import React, { useState } from "react";
import firstSection from "../assets/first-section-img.webp";
import firstSection2 from "../assets/first-section2.webp";
import firstSection3 from "../assets/first-section3.webp";
import Popup from "./Components/Popup"; 
import ContactForm from "./Components/SiteVisitForm";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./ThirdSection.css";

const UrbanNature = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false); 

    const handleButtonClick = () => {
        setIsPopupVisible(true); 
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false); 
    };

    return (
        <div className="relative px-8 pt-8 md:px-28 md:pt-16 min-h-[200px]">

            <div className="text-center md:w-full md:flex md:gap-2 md:justify-center min-h-[55px]">
                <h1 className="font-sans text-xl font-semibold text-black md:text-5xl">
                    Villas Crafted With
                </h1>
                <h1 className="font-sans text-xl font-semibold text-[#9F372A] md:text-5xl">
                    Nature’s Finest Materials
                </h1>
            </div>

            <div className="flex flex-col gap-4 pt-6 pb-4 text-center md:w-full md:gap-6 md:py-12 md:pt-12 md:text-left min-h-[150px]">
                <p className="text-justify text-sm font-thin leading-[1.3rem] md:text-base md:text-center md:leading-6">
                    Krishvi e’Villa, Premium 4 BHK Villas in Electronic City, Near Chandapura, is uniquely designed and built
                    to encourage you to live in an Eco-friendly manner, while still offering the
                    extravagance of comfort & luxury. And, with natural materials, a sense of warmth & an
                    inviting atmosphere is created, that’s both Earthy and Modern.
                </p>
            </div>

            <div className="flex flex-col gap-10">
                <div className="mt-4 md:pt-4 md:mt-0">
                    <Swiper
                        modules={[Pagination, Autoplay]}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 2500, disableOnInteraction: false }}
                        spaceBetween={30}
                        slidesPerView={1}
                        breakpoints={{
                            768: {
                                slidesPerView: 3, 
                                spaceBetween: 30,
                            },
                        }}
                        className="relative custom-swiper"
                    >
                        <SwiperSlide>
                            <img
                                src={firstSection}
                                alt="Plot in Electronic City 1"
                                className="object-cover w-full h-[180px] md:h-[310px] rounded-md"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                src={firstSection2}
                                alt="Plot in Electronic City 2"
                                className="object-cover w-full h-[180px] md:h-[310px] rounded-md"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                src={firstSection3}
                                alt="Plot in Electronic City 3"
                                className="object-cover w-full h-[180px] md:h-[310px] rounded-md"
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div className="w-full md:flex md:justify-center md:mt-8">
                <button
                    className="mt-6 px-6 py-3 font-normal md:w-1/3 text-white bg-[#9F372A] w-full rounded-md shadow-[0_4px_6px_rgba(0,0,0,0.1)] hover:bg-[#862b20] transition duration-300"
                    onClick={handleButtonClick} 
                >
                    Click to Get More Details
                </button>
            </div>

            {isPopupVisible && (
                <Popup onClose={handleClosePopup}>
                    <h2 className="text-lg text-center font-normal mb-4">Please Fill In The Form</h2>
                    <ContactForm/>
                </Popup>
            )}
        </div>
    );
};

export default UrbanNature;
