import React, { useEffect } from "react";

function ThankYouPopup({ onClose }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-434475017";
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white p-6 w-[90%] md:w-[30%] rounded-lg shadow-lg">
        <button
          className="absolute top-2 right-2 text-black font-bold"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-center text-xl font-bold mb-4">Thank You!</h2>
        <p className="text-center">
          Your form has been successfully submitted. We will get back to you
          shortly!
        </p>
      </div>
    </div>
  );
}

export default ThankYouPopup;
